import { Injectable } from '@angular/core';

declare const gtag: any; //analytics

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor() { }

  gaEvent(user_id: string, action: string, event_category: string, value: number = 20) {

    gtag('event', action,
      {
        event_category,
        // eventAction: 'Login',
        // event_label: 'Iniciar Sesión',
        event_label: user_id,
        value
      }
    );

  }

  gaConfig(user_id: string) {

    gtag('config', 'UA-152642315-1', { 'user_id': user_id });

  }

}
