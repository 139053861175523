import { Injectable } from '@angular/core';

import * as Parse from "parse";

import { UserService } from './user.service';

import { GroupModel } from '../model/group.model';
import { UserModel } from '../model/user.model';
import { TextService } from './text.service';
import { RespModel } from '../model/resp.model';
import { ErrorService } from './error.service';
import { Observable, Subject } from 'rxjs';
import { TaskService } from './task.service';
import { HistoryModel } from '../model/task.model';

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  private _groups: GroupModel[] = [];

  constructor(
    private userService: UserService,
    private textService: TextService,
    private errorService: ErrorService,
    private taskService: TaskService
  ) { }

  /**
   * Cargamos los grupos del usuario
   */
  async load(): Promise<boolean> {

    let is_complete: boolean = false;

    const user = this.userService.getSession();

    const QueryClass = Parse.Object.extend('Group');
    const query = new Parse.Query(QueryClass);

    // query.containedIn("members", user.emails);
    // query.containedIn('members', [user.id]);
    query.contains('members', user.id);

    query.equalTo('is_deleted', false);

    // query.fromLocalDatastore();

    const groupsParse = await query.find();

    const groups = groupsParse.map(g => { return { id: g.id, ...g.attributes } });

    this._groups = JSON.parse(JSON.stringify(groups));

    this.listen();

    return is_complete;

  }

  async getGroupsByUser(userid: any) {
    let is_complete: boolean = false;
    const QueryClass = Parse.Object.extend('Group');
    const query = new Parse.Query(QueryClass);
    query.contains('members', userid);
    query.equalTo('is_deleted', false);
    const groupsParse = await query.find();
    const groups = groupsParse.map(g => { return { id: g.id, ...g.attributes } });
    let list: GroupModel[] = [];
    list = JSON.parse(JSON.stringify(groups));
    return list;
  }

  /**
   * Escucha los cambios en la base de datos y dispara un observable
   */
  private listen() {

    const user = this.userService.getSession();

    const QueryClass = Parse.Object.extend('Group');

    const query = new Parse.Query(QueryClass);

    query.contains('members', user.id);

    query.subscribe().then(s => {

      s.on('create', groupParse => {

        const group = { id: groupParse.id, ...groupParse.attributes };

        console.log('Group Create', group);

        this._groups.push(group);

        //observable disparador
        this.getAllListen$.next(JSON.parse(JSON.stringify(this._groups)));

        //actualizamos el escucha de las tareas
        this.taskService.addGroupToListen(group.id);

        // //guardar la data en local
        // r.pin();

      });

      s.on('update', groupParse => {

        const group: GroupModel = { id: groupParse.id, ...groupParse.attributes };

        const index = this._groups.findIndex(g => g.id === group.id);

        if (index == -1) return;

        console.log('Group Update', group);

        this._groups[index] = group;

        //observable disparador
        this.getAllListen$.next(JSON.parse(JSON.stringify(this._groups)));

      });

      s.on('leave', groupParse => {

        const group: GroupModel = { id: groupParse.id, ...groupParse.attributes };


        const index = this._groups.findIndex(g => g.id === group.id);

        if (index == -1) return;

        console.log('Group Update', group);

        this._groups[index] = group;

        //validar que el usuario siga en el grupo actualizado
        const members_remove = group.members_remove || [];
        if (members_remove.findIndex(m_id => m_id == user.id) != -1) {

          //eliminar localmente el grupo eliminado
          this._groups = this._groups.filter(g => g.id != group.id);

        }

        //observable disparador
        this.getAllListen$.next(JSON.parse(JSON.stringify(this._groups)));

      });

    });

  }


  /**
   * Observables
   * reenvia los datos cuando se actualiza la lista
   */
  private getAllListen$: Subject<GroupModel[]> = new Subject<GroupModel[]>();
  getAllListen = this.getAllListen$.asObservable();


  /**
   * Crea atributos para un nuevo grupo
   */
  loadNew(): GroupModel {

    const user = this.userService.getSession();

    const group: GroupModel = {
      members: [
        user.id
      ],
      member_responsable: user.id
    };

    return group;

  }

  /**
   * Obtiene el grupo como objeto
   * @param group_id Id del grupo
   */
  get(group_id: string): GroupModel {

    const index = this._groups.findIndex(g => g.id == group_id);

    if (index != -1) {
      return JSON.parse(JSON.stringify(this._groups[index]));
    }

    return null;

  }


  /**
   * Obtiene el listado de grupos del usuario
   */
  getAll(): GroupModel[] {

    let list: GroupModel[] = [];

    list = JSON.parse(JSON.stringify(this._groups));

    return list;

  }


  /**
   * Obtiene los miembros de cada grupo
   * @param members_id Array de miembros a traer
   */
  getMembers(members_id: string[]): UserModel[] {

    const text = this.textService.get();
    const user = this.userService.getSession();
    let members = this.userService.getUsers(members_id);

    members.forEach(member => {

      let name = '';

      if (member.id == user.id) {

        name = text.me;

      } else {

        let names = member.fullname.split(' ');

        if (names.length >= 2) {
          name = names[0] + " " + names[1];
        } else {
          name = names[0];
        }

      }

      member.fullname = name;

    });

    //ordenamos alfabeticamente

    members.sort((a, b) => a.fullname < b.fullname ? -1 : 1);

    return members;

  }


  /**
   * Guarda el grupo
   * @param group 
   */
  async save(group: GroupModel): Promise<RespModel> {

    let resp: RespModel = { complete: false };

    const ParseClass = Parse.Object.extend('Group');
    const obj = new ParseClass();

    obj.set('name', group.name);
    obj.set('member_responsable', group.member_responsable);
    obj.set('members', group.members);
    obj.set('is_deleted', false);

    try {

      const groupParse = await obj.save();

      const group: GroupModel = {
        id: groupParse.id,
        ...groupParse.attributes,
      };

      resp.data = group;

      resp.complete = true;

      //agregar el evento al historial
      const history: HistoryModel = {

        group_id: group.id,
        event: 'created',
        field: 'name',
        value: group.name,
        group_name: group.name

      };

      this.saveHistory(history);

    } catch (error) {
      resp.message = this.errorService.convertMessage(error, 'group');
    }

    return resp;

  }


  /**
   * Actualiza el grupo
   * @param group 
   */
  async update(group: GroupModel): Promise<RespModel> {

    let resp: RespModel = { complete: false };

    const ParseClass = Parse.Object.extend('Group');

    const query = new Parse.Query(ParseClass);

    query.equalTo('objectId', group.id)

    const obj = await query.first();

    try {

      if (group.id) {
        delete group.id;
      }

      const r = await obj.save(group)

      resp.complete = true;

      const groupUpdate = {
        uid: r.id,
        ...r.attributes,
      };

      resp.data = JSON.parse(JSON.stringify(groupUpdate));

    } catch (error) {

      resp.message = this.errorService.convertMessage(error, 'group');

    }

    return resp;
  }


  /**
   * Elimina el grupo
   * @param group
   */
  async delete(group: GroupModel): Promise<RespModel> {

    let resp: RespModel = { complete: false };

    const QueryClass = Parse.Object.extend('Group');
    const query = new Parse.Query(QueryClass);


    try {

      const groupParse = await query.get(group.id);

      groupParse.set('is_deleted', true);

      await groupParse.save();

      resp.complete = true;

      //eliminar localmente el grupo eliminado
      this._groups = this._groups.filter(g => g.id != group.id);

      //observable disparador
      this.getAllListen$.next(JSON.parse(JSON.stringify(this._groups)));

      //agregar el evento al historial
      const history: HistoryModel = {

        group_id: group.id,
        event: 'deleted',
        field: 'name',
        value: group.name,
        group_name: group.name

      };

      this.saveHistory(history);

    } catch (error) {

      resp.message = this.errorService.convertMessage(error, 'task');

    }

    return resp;

  }


  /**
   * Guarda el evento en el historial de la tarea
   * @param taskHistory
   */
  async saveHistory(history: HistoryModel): Promise<RespModel> {

    let resp: RespModel = { complete: false };

    const user: UserModel = this.userService.getSession();

    const ParseClass = Parse.Object.extend('TaskHistory');
    const obj = new ParseClass();

    obj.set('user_id', user.id);
    obj.set('user_name', user.fullname);
    obj.set('type', history.type || 'group');
    obj.set('group_id', history.group_id);
    obj.set('group_name', history.group_name);

    obj.set('event', history.event);

    if (history.event == 'updated') {

      obj.set('field', history.field);
      obj.set('value', history.value);
      obj.set('old_value', history.old_value);

    } else if (history.event == 'created') {

      obj.set('field', history.field);
      obj.set('value', history.value);

    } else if (history.event == 'deleted') {

      obj.set('field', history.field);
      obj.set('value', history.value);

    }


    try {

      const taskEventParse = await obj.save();

      resp.data = {
        id: taskEventParse.id
      };

      resp.complete = true;

    } catch (error) {
      resp.message = this.errorService.convertMessage(error, 'task_history');
    }

    return resp;

  }


  /**
   * Valida si el miembro tiene tareas pendientes
   * @param member_id 
   */
  async memberHasTask(member_id: string, group_id: string): Promise<boolean> {

    let has: boolean = false;

    let queries = [];

    ['pending', 'process'].forEach(state => {

      let query = new Parse.Query('Task');
      query.equalTo('group_uid', group_id);
      query.equalTo('responsable_uid', member_id);
      query.equalTo('state', state);
      query.equalTo('is_deleted', false);
      queries.push(query);

    });

    let queryFinal = Parse.Query.or(...queries);


    try {

      const listParse = await queryFinal.find();

      console.log('listParse', listParse);

      has = listParse.length ? true : false;

    } catch (error) {

      const message = this.errorService.convertMessage(error, 'group');

      console.log('message', message);
      // this.uiService.alert(message);

    }



    return has;

  }


  /**
   * Elimina los miembros y actualiza las tareas q asignaron o en las q eran responsables 
   * @param members_id Lista de id de los miembros
   */
  async deleteMembers(members_id: string[]): Promise<RespModel> {

    let resp: RespModel = { complete: false };

    const user = this.userService.getSession();

    const ParseClass = Parse.Object.extend('Group');//Task

    const query = new Parse.Query(ParseClass);

    query.containedIn('responsable_uid', members_id);
    query.containedIn('from_uid', members_id);

    const listParse = await query.find();

    try {

      listParse.forEach(async (oP) => {

        if (members_id.includes(oP.get('responsable_uid'))) {
          oP.set('responsable_uid', user.id);
        }

        if (members_id.includes(oP.get('from_uid'))) {
          oP.set('from_uid', user.id);
        }

        await oP.save();

      });

      resp.complete = true;

    } catch (error) {

      resp.message = this.errorService.convertMessage(error, 'group');

    }

    return resp;
  }




}
