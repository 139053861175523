// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  advantask: {
    api: 'https://api.advantask.com',
    // api: 'http://localhost:3002',
    app: 'https://app.advantask.com',
    files: 'https://files.advantask.com/app',
    email: 'https://www.advantask.com/mail',
    //api2: 'http://localhost:3001'
    api2: 'https://beta.api.advantask.com'
  },
  firebase: {
    apiKey: "AIzaSyAqMX0O_xKE6XrjOQfIqOfIYG8CTgK6q8Q",
    authDomain: "advantask.firebaseapp.com",
    databaseURL: "https://advantask.firebaseio.com",
    projectId: "advantask",
    storageBucket: "advantask.appspot.com",
    messagingSenderId: "763777781786",
    appId: "1:763777781786:web:4f2df9b70c78528cfa01a3",
    measurementId: "G-31B1C82J89",
    serverKey: "AAAAsdS0EBo:APA91bGzw7k2pEtsC7S5ZC7XqlwqEKIByU23k2CQTd1ttIg-QkhbgYXHjnfgDDQ5xQCcYWLBgHfKPFNz_U9RLr_4XdEcyr84PeARIv9IKUkJuHMTX_SlwxBvgbLK_XrUDKak0zMiNbOx"
  },
  fcm: 'https://fcm.googleapis.com/fcm/send',
  google: {
    client_id: '763777781786-s1mjko8am0pibs637c155t3h0qu1i48e.apps.googleusercontent.com',
    scopes: 'profile email',
    cookiepolicy: 'single_host_origin'
  },
  parse: {
    // server: 'https://advantaskmongo.b4a.io',
    // application_id: 'jMyHKT7Gb7dnRIpVHp91QPWTGNHEMt4NK7P2bcLg',
    // javascript_key: '2RnFSoIGWf9dC1QyYhUL02qSnKCssIWZhkBxOHCv',

    // Parametros para BD de desarrollo
    //server: 'https://parseapi.back4app.com',
    //application_id: 'dpcKcpwvcFwXoLUbtSrVjvRDx86qX9E7nDMo8sDZ',
    //javascript_key: '0EDnjBjGDsXDYQCSPdIpSdkEfj6i54TXXAB5ae8L',

    // Parametros para producción
    server: 'https://advantask-prod.back4app.io',
    application_id: 'XE5sUU20XClb2ZJraBwQA2c5YRQJ2E2qjFo1KqqU',
    javascript_key: 'OjKcvB0T6AosCeyjUJY64CZ7SC41HvR7p2ddYbuC',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
