import { Injectable } from "@angular/core";

import * as Parse from "parse";

import {
  TaskModel,
  TaskOrder,
  HistoryModel,
  HistoryType,
  ReminderType,
} from "../model/task.model";
import { RespModel } from "../model/resp.model";
import { ErrorService } from "./error.service";
import { Observable, Subject } from "rxjs";

import * as moment from "moment";
import { UserService } from "./user.service";
import { UserModel } from "../model/user.model";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { TextService } from "./text.service";
import { GroupModel } from "../model/group.model";
import { UtilService } from "./util.service";

interface GroupTasksOrder {
  group_id: string;
  tasksOrder: TaskOrder[];
}

@Injectable({
  providedIn: "root",
})
export class TaskService {
  private _tasks: TaskModel[] = [];

  private _groupsTasksOrder: GroupTasksOrder[] = [];

  constructor(
    private errorService: ErrorService,
    private userService: UserService,
    private http: HttpClient,
    private textService: TextService,
    private utilService: UtilService
  ) {}

  /**
   * Cargamos las tareas de todos los grupos del usuario
   * @param group_ids Lista de ids de todos los grupos
   */
  async load(group_ids: string[]): Promise<boolean> {
    let is_complete: boolean = false;

    const QueryClass = Parse.Object.extend("Task");
    const query = new Parse.Query(QueryClass);

    query.limit(1000);
    query.containedIn("group_uid", group_ids);
    query.equalTo("is_deleted", false);

    query.ascending("createdAt");

    const taskParse = await query.find();

    const tasks = taskParse.map((t) => {
      const attributes: any = JSON.parse(JSON.stringify(t.attributes));

      delete attributes.from_uid;
      delete attributes.responsable_uid;
      delete attributes.group_uid;
      delete attributes.parent_uid;

      const task: TaskModel = {
        ...attributes,
        from_id: t.get("from_uid"),
        responsable_id: t.get("responsable_uid"),
        group_id: t.get("group_uid"),
        parent_id: t.get("parent_uid"),
      };

      return { id: t.id, ...task };
    });

    this._tasks = JSON.parse(JSON.stringify(tasks));

    this.listen(group_ids);

    this.loadTaskOrder(group_ids);

    return is_complete;
  }

  /**
   * Cargamos el orden de las tareas agrupados por grupo
   * @param group_ids
   */
  async loadTaskOrder(group_ids: string[]): Promise<boolean> {
    let is_complete: boolean = false;

    const QueryClass = Parse.Object.extend("TasksOrder");
    const query = new Parse.Query(QueryClass);

    query.limit(1000);
    query.containedIn("group_id", group_ids);

    const tasksOrderParse = await query.find();

    const groupsTasksOrder = tasksOrderParse.map((taskOrderParse) => {
      const tasksOrder: TaskOrder[] = taskOrderParse.get("tasks_order") || [];

      const groupTasksSort: GroupTasksOrder = {
        group_id: taskOrderParse.get("group_id"),
        tasksOrder,
      };

      return groupTasksSort;
    });

    this._groupsTasksOrder = JSON.parse(JSON.stringify(groupsTasksOrder));

    this.listenLoadTaskOrder(group_ids);

    return is_complete;
  }

  /**
   * Escuchamos los cambios de orden de tarea
   * @param group_ids
   */
  private listenLoadTaskOrder(group_ids: string[]) {
    const QueryClass = Parse.Object.extend("TasksOrder");
    const query = new Parse.Query(QueryClass);

    query.limit(1000);
    query.containedIn("group_id", group_ids);

    query.subscribe().then((s) => {
      s.on("create", (taskOrderParse) => {
        const tasksOrder: TaskOrder[] = taskOrderParse.get("tasks_order") || [];

        const groupTasksOrder: GroupTasksOrder = {
          group_id: taskOrderParse.get("group_id"),
          tasksOrder,
        };

        this._groupsTasksOrder.push(groupTasksOrder);
      });

      s.on("update", (taskOrderParse) => {
        console.log("taskOrderParse", taskOrderParse.attributes);

        const tasksOrder: TaskOrder[] = taskOrderParse.get("tasks_order") || [];

        const groupTasksOrder: GroupTasksOrder = {
          group_id: taskOrderParse.get("group_id"),
          tasksOrder,
        };

        const index = this._groupsTasksOrder.findIndex(
          (g) => g.group_id === groupTasksOrder.group_id
        );

        if (index == -1) return;

        this._groupsTasksOrder[index] = groupTasksOrder;
      });
    });
  }

  /**
   * Obtiene el orden de las tareas de un determinado grupo
   * @param group_id
   */
  getTaskOrder(group_id: string): TaskOrder[] {
    let list: TaskOrder[] = [];
    const index = this._groupsTasksOrder.findIndex(
      (g) => g.group_id == group_id
    );

    if (index != -1) {
      list = JSON.parse(
        JSON.stringify(this._groupsTasksOrder[index].tasksOrder)
      );
    }

    return list;
  }

  /**
   * Actualiza el orden de las tareas
   * @param group_id
   */
  async updateTaskOrder(
    group_id: string,
    tasksOrder: TaskOrder[]
  ): Promise<RespModel> {
    let resp: RespModel = { complete: false };

    const ParseClass = Parse.Object.extend("TasksOrder");

    const query = new Parse.Query(ParseClass);

    query.equalTo("group_id", group_id);
    let obj = await query.first();

    if (obj == null) {
      obj = new ParseClass();
      obj.set("group_id", group_id);
      obj.set("tasks_order", tasksOrder);
    } else {
      obj.set("tasks_order", tasksOrder);
    }

    const user = this.userService.getSession();

    obj.set("user_id", user.id);

    try {
      const r = await obj.save();

      resp.complete = true;

      const taskUpdate = {
        uid: r.id,
        ...r.attributes,
      };

      resp.data = JSON.parse(JSON.stringify(taskUpdate));

      //actualizamos los ordenes manualmente
      //a pesar de tener el escucha, porq tiene delay y el cambio no se ve reflejado
      //porq la tarea actualiza más rápido

      const groupTasksOrder: GroupTasksOrder = {
        group_id: group_id,
        tasksOrder,
      };

      const index = this._groupsTasksOrder.findIndex(
        (g) => g.group_id === groupTasksOrder.group_id
      );

      if (index != -1) {
        this._groupsTasksOrder[index] = groupTasksOrder;
      }
    } catch (error) {
      resp.message = this.errorService.convertMessage(error, "task");
    }

    return resp;
  }

  /**
   * Agrega un grupo al listado de escucha
   * @param group_id Id del nuevo grupo
   */
  addGroupToListen(group_id: string) {
    this.listen([group_id]);

    this.listenLoadTaskOrder([group_id]);
  }

  /**
   * Escucha los cambios en la base de datos y dispara un observable
   * @param group_ids Lista de ids de todos los grupos
   */
  private listen(group_ids: string[]) {
    const QueryClass = Parse.Object.extend("Task");
    const query = new Parse.Query(QueryClass);

    query.limit(1000);
    query.containedIn("group_uid", group_ids);
    // Se borró para que escuche los eventos de actualización también
    //query.equalTo("is_deleted", false);
    //query.equalTo("is_deleted", true);

    query.ascending("createdAt");

    query.subscribe().then((s) => {
      s.on("create", (taskParse) => {
        // Solo si la tarea no está borrada
        if (!taskParse.get("is_deleted")) {
          const attributes: any = JSON.parse(
            JSON.stringify(taskParse.attributes)
          );

          delete attributes.from_uid;
          delete attributes.responsable_uid;
          delete attributes.group_uid;
          delete attributes.parent_uid;

          const task: TaskModel = {
            ...attributes,
            from_id: taskParse.get("from_uid"),
            responsable_id: taskParse.get("responsable_uid"),
            group_id: taskParse.get("group_uid"),
            parent_id: taskParse.get("parent_uid"),
            id: taskParse.id,
          };

          console.log("Task Create", task);
          //notifico el evento de creación
          this.getEventListener$.next({ ...task, event: "created" });

          this._tasks.push(task);

          //observable disparador
          this.getAllListen$.next(JSON.parse(JSON.stringify(this._tasks)));

          // //guardar la data en local
          // r.pin();
        }
      });

      s.on("update", async (taskParse) => {
        const QueryClass2 = Parse.Object.extend("Task");
        const query2 = new Parse.Query(QueryClass2);
        const tParse = await query2.get(taskParse.id);

        const attributes: any = JSON.parse(
          JSON.stringify(taskParse.attributes)
        );

        delete attributes.from_uid;
        delete attributes.responsable_uid;
        delete attributes.group_uid;
        delete attributes.parent_uid;

        const task: TaskModel = {
          ...attributes,
          from_id: taskParse.get("from_uid"),
          responsable_id: taskParse.get("responsable_uid"),
          group_id: taskParse.get("group_uid"),
          parent_id: taskParse.get("parent_uid"),
          id: taskParse.id,
        };
        //console.log("Task Updated", task);

        const index = this._tasks.findIndex((t) => t.id === task.id);

        if (index == -1) {
          // El elemento ha sido eliminado de forma local
          this.getEventListener$.next({ event: "deleted" });
          return;
        }

        // El elemento ha sido actualizado de forma remota o local
        if (!taskParse.get("is_deleted")) {
          // Reemplazo el elemento
          this._tasks[index] = task;
          //notifico el evento de actualización (cualquier campo se pudo haber actualizado)
          this.getEventListener$.next({ ...task, event: "updated" });
          
        } else {
          // El elemento ha sido eliminado de forma remota
          // Elimino el elemento
          this._tasks.splice(index, 1);
          //notifico el evento de eliminación
          this.getEventListener$.next({ ...task, event: "deleted" });
        }

        //this._tasks[index] = task;

        //observable disparador
        this.getAllListen$.next(JSON.parse(JSON.stringify(this._tasks)));
      });
    });
  }

  /**
   * Observables
   * reenvia los datos cuando se actualiza la lista
   */
  private getAllListen$: Subject<TaskModel[]> = new Subject<TaskModel[]>();
  getAllListen = this.getAllListen$.asObservable();

  // Escucha los eventos de creación, actualización y eliminación
  private getEventListener$: Subject<TaskModel> = new Subject<TaskModel>();
  getEventListener = this.getEventListener$.asObservable();

  /**
   * Guarda el evento en el historial de la tarea
   * @param taskHistory
   */
  async saveHistory(history: HistoryModel): Promise<RespModel> {
    let resp: RespModel = { complete: false };

    const user: UserModel = this.userService.getSession();

    const ParseClass = Parse.Object.extend("TaskHistory");
    const obj = new ParseClass();

    obj.set("user_id", user.id);
    obj.set("user_name", user.fullname);
    obj.set("type", "task");
    obj.set("group_id", history.group_id);
    obj.set("group_name", history.group_name);

    obj.set("task_id", history.task_id);
    obj.set("task_name", history.task_name);

    obj.set("event", history.event);

    if (history.event == "updated") {
      obj.set("field", history.field);
      obj.set("value", history.value);
      obj.set("old_value", history.old_value);
    } else if (history.event == "created") {
      obj.set("field", history.field);
      obj.set("value", history.value);
    } else if (history.event == "deleted") {
      obj.set("field", history.field);
      obj.set("value", history.value);
    }

    try {
      const taskEventParse = await obj.save();

      resp.data = {
        id: taskEventParse.id,
      };

      resp.complete = true;
    } catch (error) {
      resp.message = this.errorService.convertMessage(error, "task_history");
    }

    return resp;
  }

  /**
   *
   * @param type El tipo de historia si es null trae las primeras 200 historias
   * @param history_id El id del tipo de historia
   */
  async getHistory(
    type: HistoryType = null,
    history_id: string = null,
    group_ids: string[] = []
  ): Promise<HistoryModel[]> {
    let list: HistoryModel[] = [];

    const QueryClass = Parse.Object.extend("TaskHistory");
    const query = new Parse.Query(QueryClass);

    switch (type) {
      case "group":
        query.equalTo("group_id", history_id);
        query.limit(20);

        break;
      case "task":
        query.equalTo("task_id", history_id);
        query.limit(10);

        break;
      default:
        console.log("group_ids", group_ids);

        query.containedIn("group_id", group_ids);
        query.limit(200);
        break;
    }

    query.descending("createdAt");

    const historyParse = await query.find();

    const histories = historyParse.map((hP) => {
      const attributes: any = JSON.parse(JSON.stringify(hP.attributes));

      const history: HistoryModel = {
        ...attributes,
      };

      return { id: hP.id, ...history };
    });

    list = JSON.parse(JSON.stringify(histories));

    return list;
  }

  /**
   * Obtiene la tarea como objeto
   * @param task_id Id del grupo
   */
  get(task_id: string): TaskModel {
    const index = this._tasks.findIndex((t) => t.id == task_id);

    if (index != -1) {
      return JSON.parse(JSON.stringify(this._tasks[index]));
    }

    return null;
  }

  /**
   * Obtiene el listado de tareas x grupo
   * @param group_id Id del grupo, sino se envía trae todas las tareas
   */
  getAll(group_id?: string): TaskModel[] {
    let list: TaskModel[] = [];

    if (group_id != null) {
      const tasks = this._tasks.filter((t) => t.group_id == group_id);

      list = JSON.parse(JSON.stringify(tasks));
    } else {
      list = JSON.parse(JSON.stringify(this._tasks));
    }

    return list;
  }

  /**
   * Obtiene el listado de tareas que tienen vencimiento
   * @param days Máximo de días en q debe vencerse la tarea
   * @param person Mís tareas o todas las tareas
   */
  getAllDue(days: number = 3, person: string = "all"): TaskModel[] {
    let list: TaskModel[] = [];

    let max_due = moment().add(days, "day").toDate();

    let tasks: TaskModel[] = JSON.parse(JSON.stringify(this._tasks));
    tasks = tasks.filter((t) => t.due && t.state != "complete");
    tasks = tasks.filter((t) => moment(t.due).toDate() < max_due);

    if (person == "me") {
      const user: UserModel = this.userService.getSession();
      tasks = tasks.filter((t) => t.responsable_id == user.id);
    }

    //ordenar por fecha de vencimiento
    tasks.sort((a, b) => {
      return moment(a.due) < moment(b.due) ? -1 : 1;
    });

    list = JSON.parse(JSON.stringify(tasks));

    return list;
  }

  /**
   * Guarda la tarea
   * @param task
   */
  async save(task: TaskModel): Promise<RespModel> {
    let resp: RespModel = { complete: false };

    const ParseClass = Parse.Object.extend("Task");
    const obj = new ParseClass();

    obj.set("name", task.name);
    obj.set("group_uid", task.group_id);
    obj.set("from_uid", task.from_id);
    obj.set("responsable_uid", task.responsable_id);
    obj.set("importance", 1);
    obj.set("state", "pending");
    obj.set("is_deleted", false);
    obj.set("type_id", "enOBphyD3X");

    if (task.due) {
      obj.set("due", task.due);
    }

    if (task.parent_id) {
      obj.set("parent_uid", task.parent_id);

      //validar que el padre no fue eliminado
      const index = this._tasks.findIndex((t) => t.id == task.parent_id);

      if (index == -1) {
        const text = this.textService.get();

        resp.message = text.no_task_parent;
        return resp;
      }
    }

    try {
      const taskParse = await obj.save();

      const newTask: TaskModel = {
        id: taskParse.id,
        ...taskParse.attributes,
        group_id: task.group_id,
      };

      if (newTask.due) {
        this.sendDue(newTask);
      }

      resp.data = newTask;

      resp.complete = true;
    } catch (error) {
      resp.message = this.errorService.convertMessage(error, "task");
    }

    return resp;
  }

  /**
   * Actualiza la tarea
   * @param task
   */
  async update(task: TaskModel): Promise<RespModel> {
    let resp: RespModel = { complete: false };

    const ParseClass = Parse.Object.extend("Task");

    const query = new Parse.Query(ParseClass);

    // query.equalTo('objectId', task.id)
    // const obj = await query.first();
    const obj = await query.get(task.id);

    try {
      if (task.id) {
        delete task.id;
      }

      if (task.responsable_id) {
        task["responsable_uid"] = task.responsable_id;
      }

      if (task.parent_id || task.parent_id == null) {
        task["parent_uid"] = task.parent_id;
      }

      const r = await obj.save(task);

      resp.complete = true;

      const taskUpdate: TaskModel = {
        id: r.id,
        ...r.attributes,
        group_id: r.get("group_uid"),
      };

      resp.data = JSON.parse(JSON.stringify(taskUpdate));

      console.log("resp.data", JSON.parse(JSON.stringify(resp.data)));

      if (task.due) {
        this.sendDue(taskUpdate);
      }
    } catch (error) {
      resp.message = this.errorService.convertMessage(error, "task");
    }

    return resp;
  }

  /**
   * Elimina la tarea y sus hijos
   * @param task
   * @param group
   */
  async delete(task: TaskModel, group: GroupModel): Promise<RespModel> {
    let resp: RespModel = { complete: false };

    //ordenar como arbol
    const tasks_in_group = this._tasks.filter(
      (t) => t.group_id == task.group_id
    );
    const tasks = this.listToTree(JSON.parse(JSON.stringify(tasks_in_group)));

    // console.log('tasks', tasks);

    //buscar la tarea en el arbol
    let currentTask: TaskModel = this.findTaskWithChildren(task, tasks);

    // console.log('currentTask', currentTask);

    //obtener todos los ids de todos los hijos de la tarea
    const task_ids: string[] = this.taskIdsChildren(currentTask);

    //guarda datos de la tarea
    const tasksInfo: { id: string; name: string }[] =
      this.taskInfoChildren(currentTask);

    // console.log('task_ids', task_ids);

    const QueryClass = Parse.Object.extend("Task");
    const query = new Parse.Query(QueryClass);

    query.limit(1000);
    query.containedIn("objectId", task_ids);
    query.equalTo("is_deleted", false);

    const taskParse = await query.find();

    taskParse.forEach((t) => {
      t.set("is_deleted", true);
    });

    try {
      await Parse.Object.saveAll(taskParse);

      resp.complete = true;

      //eliminar localmente las tareas eliminadas
      this._tasks = this._tasks.filter((t) => {
        return task_ids.includes(t.id) ? false : true;
      });

      //observable disparador
      this.getAllListen$.next(JSON.parse(JSON.stringify(this._tasks)));

      //agregar el evento al historial
      tasksInfo.forEach((t) => {
        //agregar el evento al historial
        const history: HistoryModel = {
          group_id: task.group_id,
          group_name: group.name,
          task_id: t.id,
          event: "deleted",
          field: "name",
          value: t.name,
          task_name: t.name,
        };

        this.saveHistory(history);
      });
    } catch (error) {
      resp.message = this.errorService.convertMessage(error, "task");
    }

    return resp;
  }

  /**
   * Retorna el id de la tarea y la de sus hijos
   * @param task
   */
  private taskIdsChildren(task: TaskModel): string[] {
    let task_ids: string[] = [task.id];

    task.children.forEach((t) => {
      task_ids = [...task_ids, ...this.taskIdsChildren(t)];
    });

    return task_ids;
  }

  /**
   * Retorna el id de la tarea y la de sus hijos
   * @param task
   */
  private taskInfoChildren(task: TaskModel): { id: string; name: string }[] {
    let tasks_info: { id: string; name: string }[] = [
      {
        id: task.id,
        name: task.name,
      },
    ];

    task.children.forEach((t) => {
      tasks_info = [...tasks_info, ...this.taskInfoChildren(t)];
    });

    return tasks_info;
  }

  /**
   * Retorna la tarea con sus hijos
   * @param task
   * @param tasks
   */
  private findTaskWithChildren(task: TaskModel, tasks: TaskModel[]): TaskModel {
    let currentTask: TaskModel = null;

    for (let i = 0; i < tasks.length; i++) {
      const t = tasks[i];

      if (t.id == task.id) {
        currentTask = JSON.parse(JSON.stringify(t));
      } else {
        currentTask = this.findTaskWithChildren(task, t.children);
      }

      if (currentTask) {
        break;
      }
    }

    return currentTask;
  }

  /**
   * Ordena las tareas en forma de arbol
   * @param list tareas a listar
   */
  private listToTree(list: TaskModel[]): TaskModel[] {
    let roots: TaskModel[] = [];
    let map = {},
      node: TaskModel = {};

    for (let i = 0; i < list.length; i += 1) {
      map[list[i].id] = i; // initialize the map
      list[i].children = []; // initialize the children
    }

    for (let i = 0; i < list.length; i += 1) {
      node = list[i];
      node.parents = [];
      node.show_children = true;
      node.is_visible = true;

      if (node.parent_id && list[map[node.parent_id]]) {
        node.level = 1;
        // if you have dangling branches check that map[node.parentId] exists
        list[map[node.parent_id]].children.push(node);
      } else {
        node.level = 0;
        roots.push(node);
      }
    }

    roots.forEach((r) => {
      r.children.forEach((t) => {
        this.listToTreeHelper(t, r);
      });
    });

    return roots;
  }

  /**
   * Bucle de listToTree
   * @param task
   * @param taskParent
   */
  private listToTreeHelper(task: TaskModel, taskParent: TaskModel) {
    task.parents = [...taskParent.parents, taskParent.id];
    task.level = taskParent.level + 1;
    task.children.forEach((t) => {
      this.listToTreeHelper(t, task);
    });
  }

  /**
   * Guardamos las alertas con vencimiento en la bd
   * @param task
   */
  private sendDue(task: TaskModel): Observable<RespModel> {
    let sub = new Subject<RespModel>();
    let resp: RespModel = {
      complete: false,
    };

    const url = `${environment.advantask.api}/alert`;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    const task_send = {
      uid: task.id,
      group_uid: task.group_id,
      due: task.due,
    };

    this.http
      .post(`${url}/save`, `${JSON.stringify(task_send)}`, httpOptions)
      .subscribe(
        (r: any) => {
          resp.complete = true;
        },
        (error) => {
          resp.message = error;
        },
        () => {
          sub.next(resp);
        }
      );

    return sub;
  }

  /**
   * Guardamos los recordatorios en la bd
   * @param task
   * @param reminder
   */
  sendReminder(task: TaskModel, reminder: ReminderType): Observable<RespModel> {
    let sub = new Subject<RespModel>();
    let resp: RespModel = {
      complete: false,
    };

    const url = `${environment.advantask.api}/alert`;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    const task_send = {
      uid: task.id,
      group_uid: task.group_id,
      reminder,
    };

    this.http
      .post(`${url}/save_reminder`, `${JSON.stringify(task_send)}`, httpOptions)
      .subscribe(
        (r: any) => {
          resp.complete = true;
        },
        (error) => {
          resp.message = error;
        },
        () => {
          sub.next(resp);
        }
      );

    return sub;
  }
}
