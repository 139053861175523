import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class TextService {
  private _url = `${environment.advantask.api}`;
  private _locale: string = null;
  private _text: any = {};
  private _textBase: any = {};

  constructor(private http: HttpClient) {
    console.log("navigator", navigator.language);
  }

  init(): Observable<any> {
    let sub = new Subject<boolean>();

    //verificamos si existe el language en el sistema
    let language = localStorage.getItem("language");
    let version = "0"; //version del language
    let variant = "1"; //tipo de contenido

    if (language == null) {
      //si no existe
      //obtenemos el language del navegador
      const navigator_language = navigator.language;
      if (navigator_language.search("es") != -1) {
        //validamos si es español
        language = "es";
      } else {
        //damos por defecto ingles
        language = "en";
      }
      localStorage.setItem("language", language);
    } else {
      version = localStorage.getItem("version");
      variant = localStorage.getItem("variant");
    }

    this._locale = language;

    //validamos la ultima version del language
    //TODO: Obtener el texto del servidor
    this.http
      .get(`${this._url}/text?version=${version}`)
      .subscribe((r: any) => {
        if (r.complete) {
          if (!r.data.text) {
            //es la misma version
            this._textBase = JSON.parse(localStorage.getItem("text"));
            this._text = this.translate(this._textBase);
          } else {
            //actualizamos datos en localstorage
            localStorage.setItem("version", r.data.version);
            localStorage.setItem("variant", r.data.variant);
            localStorage.setItem("text", JSON.stringify(r.data.text));
            this._textBase = r.data.text;
            this._text = this.translate(this._textBase);
          }
        }
        sub.next(r);
        sub.complete();
      });
    return sub;
  }

  /**
   * Setea el lenguaje a usar
   * @param locale Language 'en' , 'es', etc
   */
  setLocale(locale: string = "es") {
    this._locale = locale;
  }

  /**
   * Obtiene el lenguaje a usar
   */
  getLocale(): string {
    return this._locale;
  }

  /**
   * Retorna los textos de la página solicitada
   * @param page Página solicitada
   */
  get(page: string = "data", with_basic: boolean = true) {
    const text = JSON.parse(JSON.stringify(this._text));

    if (with_basic && page != "data") {
      return { ...text["data"], ...text[page] };
    } else {
      return { ...text[page] };
    }
  }

  /**
   * Traduce los objetos en un string
   * @param list listado de los textos a traducir
   */
  private translate(list: any[]) {
    let list_aux = {};

    for (const page in list) {
      list_aux[page] = {};
      for (const key in list[page]) {
        const text_object = list[page][key];
        list_aux[page][key] = text_object[this._locale] || text_object["en"];
      }
    }

    return list_aux;
  }
}
