import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor() { }

  /**
   * Obtiene el token
   */
  getToken() {
    //const token = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6Ik91RkhZQXdlQlQiLCJpYXQiOjE2MjE2NjExNjJ9.Aq-Hti_gP-TiOJ-Gtt8jgI1hdypHO0rI8Z8mdBjcaZw';
    const token = localStorage.getItem('api2_token');
    return token;
  }

  /**
   * Guarda el token
   */
  async setToken(token: string) {

    localStorage.setItem('api2_token', token);

  }

}
