import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  private url = `${environment.advantask.email}`;
  private httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded"
    })
  };


  constructor(
    private http: HttpClient
  ) { }

  convertMessage(r: any, type: string = 'all'): string {


    // if(r.message == 'Invalid session token'){

    // }

    if (type === 'auth') {
      return this.convertAuth(r);
    }

    if (type === 'contact') {
      return this.convertContact(r);
    }

    if (type === 'profile') {
      return this.convertProfile(r);
    }

    if (type === 'feedback') {
      return this.convertFeedback(r);
    }

    if (type === 'user') {
      return this.convertUser(r);
    }

    let message: string = 'Error connecting';

    switch (r.code) {
      case 'auth/network-request-failed':
        // message = 'Hubo un error en la conexión, revisar wifi o datos';
        message = 'Connection error: Check your Wifi or data connection';
        break;
      case 'auth/too-many-requests':
        // message = 'Se bloqueo su solicitud';
        message = 'Your request has been blocked';
        break;
      case 'auth/user-disabled':
        // message = 'Usuario desactivado';
        message = 'User not active';
        break;
      case 'auth/email-already-in-use':
        // message = 'El correo ya se encuentra registrado';
        message = 'Email is already registered';
        break;
      case 'auth/user-not-found':
        // message = 'El correo no se encuentra registrado';
        // message = 'Correo o contraseña invalido';
        message = 'Invalid email or password';
        if (type === 'recovery') {
          // message = 'El correo no se encuentra registrado';
          message = 'Email is not registered';
        }
        break;
      case 'auth/invalid-email':
        // message = 'Correo invalido';
        message = 'Invalid email';
        break;
      case 'auth/wrong-password':
        //message = 'La constraseña no es valida';
        // message = 'Correo o contraseña invalido';
        message = 'Invalid password or password';
        break;
      case 'auth/weak-password':
        // message = 'La contraseña debe tener al menos 6 caracteres';
        message = 'Password must be at least 6 characters';
        break;
      case 'auth/popup-closed-by-user':
        // message = 'El registro fue cancelado';
        message = 'Popup has been closed by the user before finalizing the operation.';
        break;
      case 'auth/cancelled-popup-request':
        // message = 'El registro fue cancelado';
        message = 'Registration has been canceled';
        break;
      case 'auth/recovery-password-complete':
        // message = 'Se envió un correo para recuperar la contraseña';
        message = 'An email has been sent to retrieve the password';
        break;
      case 'mail_invitation_complete':
        message = 'Invitation has been sent';
        break;
      case 'mail_invitation_error':
        message = 'Invitation has been not sent';
        break;
      default:
        this.sendMailToAdmin(r);
        break;
    }

    return message;
  }


  private convertAuth(r: any) {
    let message: string = r.message || 'Error Auth';

    switch (r.code) {
      case '10':
        message = 'Error Google Token';
      default:
        this.sendMailToAdmin(r);
        break;
    }

    return message;
  }

  private convertUser(r: any) {
    let message: string = r.message || 'Error User';

    switch (r.code) {
      case 209:
        message = r.message;
        break;
      default:
        this.sendMailToAdmin(r);
        break;
    }

    return message;
  }


  private convertContact(r: any) {
    let message: string = 'Error adding contact';

    switch (r.code) {
      case 'no-user':
        message = 'User is not registered, an invitation has been sent';
        break;
      default:
        this.sendMailToAdmin(r);
        break;
    }

    return message;
  }


  private convertProfile(r: any) {
    let message: string = 'Error updating profile';

    switch (r.code) {
      case 'profile/add-email':
        message = 'Email is already used by another account';
        break;
      default:
        this.sendMailToAdmin(r);
        break;
    }

    return message;
  }

  private convertFeedback(r: any) {
    let message: string = 'Error sending feedback';

    switch (r.code) {
      case 'feedback/thanks':
        message = 'Thanks for sending us feedback';
        break;
      default:
        this.sendMailToAdmin(r);
        break;
    }

    return message;
  }


  sendMailToAdmin(data: any) {//send message to admin //rcondori.89@gmail.com

    if (data.code && data.code === 'permission-denied') {
      return;
    }
    // console.log('sendMailToAdmin', data);

    // this.http.post(`${this.url}/mailError`, `data=${JSON.stringify(data)}`, this.httpOptions).subscribe();

  }

}
