import { Component, OnInit, Input } from '@angular/core';
import { UserModel } from 'src/app/model/user.model';
import { GroupModel } from 'src/app/model/group.model';
import { CommunicationService } from 'src/app/services/communication.service';

@Component({
  selector: 'app-menu-list-layout',
  templateUrl: './menu-list-layout.component.html',
  styleUrls: ['./menu-list-layout.component.scss'],
})
export class MenuListLayoutComponent implements OnInit {

  @Input() text: any;

  @Input() user: UserModel;
  @Input() groups: GroupModel[];

  constructor(
    private communicationService: CommunicationService,
  ) { }

  ngOnInit() { }

  onCloseMenu() {
    this.communicationService.setData({
      page: 'main',
      action: 'menu',
      data: {
        menu: false
      }
    });
  }

}
