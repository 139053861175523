import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

export class CommunicationInterface {
  action?: string; //action to do
  data: any; //data
  page?: string; //service or page to listen  
};

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {

  constructor() { }
  // Observable sources
  private dataSource = new Subject<CommunicationInterface>();

  private dataSourceBehavior = new BehaviorSubject<CommunicationInterface>({ data: {} });

  // Observable streams
  getDataObservable = this.dataSource.asObservable();

  getDataObservableBehavior = this.dataSourceBehavior.asObservable();

  setData(data: CommunicationInterface) {
    this.dataSource.next(data);
  }

  setDataBehavior(data: CommunicationInterface) {
    this.dataSourceBehavior.next(data);
  }

}
