import { Component, OnInit, Input } from '@angular/core';
import { UserModel } from 'src/app/model/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { CommunicationService } from 'src/app/services/communication.service';


declare const window: any;

@Component({
  selector: 'app-menu-footer-layout',
  templateUrl: './menu-footer-layout.component.html',
  styleUrls: ['./menu-footer-layout.component.scss'],
})
export class MenuFooterLayoutComponent implements OnInit {

  @Input() text: any;

  constructor(
    private authService: AuthService,
    private communicationService: CommunicationService,
  ) { }

  ngOnInit() { }

  async onLogout() {

    const is_logout = await this.authService.logout();

    if (is_logout) {
      window.location.href = '/';
    } else {
      alert("Error Logout");
    }

  }


  onCloseMenu() {
    this.communicationService.setData({
      page: 'main',
      action: 'menu',
      data: {
        menu: false
      }
    });
  }

}
