import { Injectable } from '@angular/core';


// import * as Parse from 'parse';

// import { Parse } from "parse";
import * as Parse from "parse";
import { environment } from 'src/environments/environment';

// import * as SecureLS from "secure-ls";
import { UserService } from './user.service';
import { GroupService } from './group.service';
import { TaskService } from './task.service';
import { UserModel } from '../model/user.model';

@Injectable({
  providedIn: 'root'
})
export class LoadService {

  constructor(
    private userService: UserService,
    private groupService: GroupService,
    private taskService: TaskService,
  ) { }

  initParse() {

    // Parse.serverURL = environment.parse.server;
    (Parse as any).serverURL = environment.parse.server;
    Parse.initialize(environment.parse.application_id, environment.parse.javascript_key);

    // const ls = new SecureLS({ isCompression: false });

    // Parse.enableLocalDatastore();
    // Parse.setLocalDatastoreController({
    //   fromPinWithName: name => ls.get(name),
    //   pinWithName: (name, objects) => ls.set(name, JSON.stringify(objects)),
    //   unPinWithName: name => ls.remove(name),
    //   getAllContents: () => {
    //     let data = {};
    //     ls.getAllKeys().forEach((key) => {
    //       const value = ls.get(key).data || ls.get(key);
    //       data[key] = value.includes('{') ? JSON.parse(value) : value;
    //     })
    //     return data;
    //   },
    //   clear: () => ls.removeAll()
    // });

  }

  /**
   * Cargamos los datos necesarios y lo almacenamos en un bloc
   */
  async init() {

    const user: UserModel = this.userService.getSession();

    //load groups
    await this.groupService.load();

    const groups = this.groupService.getAll();

    const groups_id = groups.map(g => g.id);
    //load tasks
    await this.taskService.load(groups_id);


    let user_ids: string[] = [user.id, ...user.contacts];

    groups.forEach(g => {
      let members_remove = g.members_remove || [];
      user_ids = [...user_ids, ...g.members, ...members_remove];
    });


    //load users
    await this.userService.load(user_ids);

  }


  async updateBD() {



    //obtener todos los usuarios

    const QueryClass = Parse.Object.extend('UserConfig');
    const query = new Parse.Query(QueryClass);

    query.limit(2000);
    const usersParse = await query.find();

    console.log('usersParse.length', usersParse.length);
    // console.log('usersParse.length', usersParse);


    // this.updateUsers(usersParse);
    // this.updateGroups(usersParse);

    // this.updateTasks();

  }


  private async updateUsers(usersParse: Parse.Object<Parse.Attributes>[]) {


    //iterar el contacto de cada user

    usersParse.forEach(uP => {

      let contactsEmail: string[] = uP.get('contacts');

      let newContacts: string[] = [];

      //si es diferente de null
      if (contactsEmail) {

        //iterar cada contacto (es un email) 
        //buscar ese email dentro de los usuarios  
        //cambiar el email por el id del usuario encontrado
        contactsEmail.forEach(c => {

          const index = usersParse.findIndex(uP => {

            const emails: string[] = uP.get('emails');

            const index = emails.findIndex(e => e == c);

            return index != -1 ? true : false;

          });

          if (index != -1) {

            newContacts.push(usersParse[index].id);

          }

        });

      }

      uP.set('contacts', newContacts);

      uP.set('options', {
        agroup_by: 'person',
        sort_by: 'created',
        show_by: [],
        dashboard_day: 3,
        dashboard_person: 'me'
      });

      // uP.save();

      // console.log('uP.attributes', uP.attributes);

    });


    await Parse.Object.saveAll(usersParse);

  }

  private async updateGroups(usersParse: Parse.Object<Parse.Attributes>[]) {
    //update groups

    //obtener todos los grupos


    const QueryClass = Parse.Object.extend('Group');
    const query = new Parse.Query(QueryClass);

    query.limit(1000);
    const groupsParse = await query.find();

    console.log('groupsParse.length', groupsParse.length);

    //iterar cada grupo
    //obtener los miembros y el miembro responsable
    //cambiar el email por el id del usuario


    groupsParse.forEach(gP => {

      const members: string[] = gP.get('members');
      const memberResponsable: string = gP.get('member_responsable');

      let newMembers: string[] = [];
      let newMemberResponsable: string = '';

      members.forEach(m => {

        const index = usersParse.findIndex(uP => {

          const emails: string[] = uP.get('emails');

          const index = emails.findIndex(e => e == m);

          return index != -1 ? true : false;

        });

        if (index != -1) {

          newMembers.push(usersParse[index].id);

        }

      });

      gP.set('members', newMembers);


      const index = usersParse.findIndex(uP => {

        const emails: string[] = uP.get('emails');

        const index = emails.findIndex(e => e == memberResponsable);

        return index != -1 ? true : false;

      });

      if (index != -1) {

        gP.set('member_responsable', usersParse[index].id);

      }

      gP.set('is_deleted', false);


      // gP.save();

      // console.log('gP.attributes', gP.attributes);

    });

    await Parse.Object.saveAll(groupsParse);

  }


  private async updateTasks() {


    const QueryClass = Parse.Object.extend('Task');
    const query = new Parse.Query(QueryClass);

    query.limit(2000);
    const tasksParse = await query.find();

    tasksParse.forEach(tP => {

      if (!tP.get('importance')) {

        tP.set('importance', 1);

        // tP.save();

      }

    });

    await Parse.Object.saveAll(tasksParse);


  }

}
