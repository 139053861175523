import { Component, OnInit } from '@angular/core';
import { TextService } from 'src/app/services/text.service';
import { UserModel } from 'src/app/model/user.model';
import { UserService } from 'src/app/services/user.service';
import { GroupModel } from 'src/app/model/group.model';
import { GroupService } from 'src/app/services/group.service';
import { CommunicationService, CommunicationInterface } from 'src/app/services/communication.service';
import { filter } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DeviceService } from 'src/app/services/device.service';
@Component({
  selector: 'app-menu-layout',
  templateUrl: './menu-layout.component.html',
  styleUrls: ['./menu-layout.component.scss'],
})
export class MenuLayoutComponent implements OnInit {

  is_chrome_device: boolean = false;

  text: any;

  user: UserModel;
  groups: GroupModel[] = [];

  constructor(
    private textService: TextService,
    private userService: UserService,
    private groupService: GroupService,
    private communicationService: CommunicationService,
    private router: Router,
    private deviceService: DeviceService,
  ) { }

  ngOnInit() {

    this.is_chrome_device = this.deviceService.isChromeDevice();

    this.text = this.textService.get('menu');

    this.user = this.userService.getSession();

    // this.user.image = 'https://static.toiimg.com/thumb/72975551.cms?width=680&height=512&imgsize=881753';

    this.load();

  }

  load() {

    this.groups = this.groupService.getAll();

    this.groupService.getAllListen.subscribe(groups => {
      this.groups = groups;
    });

    this.userService.getUserListen.subscribe(user => {
      this.user = user;
    });

    this.listen();

  }

  listen() {

    this.communicationService.getDataObservable.pipe(
      filter((communicationData: CommunicationInterface) => {
        return communicationData.page === 'menu';
      })
    ).subscribe((communicationData: CommunicationInterface) => {

      if (communicationData.action == 'init') {

        if (this.groups.length) {

          this.router.navigate(['/' + this.groups[0].id]);

        } else {

          this.router.navigate(['/group/new']);

        }

      }


    });

  }

}
