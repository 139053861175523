import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor() { }

  /**
   * TimeOut
   * @param ms Milisegundos
   */
  timeout(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


}
