import { Component, HostListener } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { LoadService } from './services/load.service';
import { environment } from 'src/environments/environment';
import { TextService } from './services/text.service';
import { MetricsService } from './services/metrics.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  is_load: boolean = false;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private loadService: LoadService,
    private textService: TextService,
    private metricsService: MetricsService,
  ) {
    console.log('version', "2.0.0", "Desacoplamiento");
    this.initializeApp();

    // window.onbeforeunload = async (e) => {
    //   console.log('ok',);

    //   await this.metricsService.save('end', 'global');

    //   return alert("Sure");
    // };
  }

  @HostListener('window:beforeunload', ['$event'])
  async doSomething($event) {
    // if(this.hasChanges) $event.returnValue='Your data will be lost!';
    await this.metricsService.save('end', 'global');
    // $event.returnValue='Your data will be lost!';
    // return alert("Sure");
  }

  initializeApp() {
    this.platform.ready().then(() => {
      // this.statusBar.styleDefault();
      this.statusBar.backgroundColorByHexString("#1a76c1");
      this.splashScreen.hide();
    });

    this.load();
  }

  load() {
    this.loadService.initParse();

    //obtenemos el texto
    this.textService.init().subscribe(async (r) => {
      if (r.complete) {

        await this.metricsService.init();
        this.is_load = true;

      } else {
        alert(r.message);
      }
    });

    // this.updateDB();

    if (environment.production) {
      return;
    }
  }

  updateDB() {

    this.loadService.updateBD();

  }

}
