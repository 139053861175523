import { Component, OnInit, Input } from '@angular/core';
import { UserModel } from 'src/app/model/user.model';
import { CommunicationService } from 'src/app/services/communication.service';

@Component({
  selector: 'app-menu-profile-layout',
  templateUrl: './menu-profile-layout.component.html',
  styleUrls: ['./menu-profile-layout.component.scss'],
})
export class MenuProfileLayoutComponent implements OnInit {

  @Input() text: any;

  @Input() user: UserModel;

  constructor(
    private communicationService: CommunicationService,
  ) { }

  ngOnInit() {}

  getName() {

    let names = this.user.fullname.split(' ');
    let name = '';

    if (names.length >= 2) {
      name = names[0] + " " + names[1];
    } else {
      name = names[0];
    }

    return name;

  }

  onCloseMenu() {
    this.communicationService.setData({
      page: 'main',
      action: 'menu',
      data: {
        menu: false
      }
    });
  }

}
