import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

declare const window: any;

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  constructor(
    public platform: Platform,
  ) { }

  isDevice(): boolean {

    let is_device: boolean = false;

    if (this.platform.is('android') || this.platform.is('ios')) {
      is_device = true;
    }

    return is_device;

  }

  isChromeDevice(): boolean {
    let is_chrome: boolean = false;

    if (this.platform.is('android') || this.platform.is('ios')) {

      const _isChromium = window.chrome;
      const _winNav = window.navigator;
      const _vendorName = _winNav.vendor;
      const _isOpera = typeof window.opr !== "undefined";
      const _isIEedge = _winNav.userAgent.indexOf("Edge") > -1;
      const _isIOSChrome = _winNav.userAgent.match("CriOS");

      if (_isIOSChrome) {
        // is Google Chrome on IOS
        is_chrome = true;
      } else if (
        _isChromium !== null &&
        typeof _isChromium !== "undefined" &&
        _vendorName === "Google Inc." &&
        _isOpera === false &&
        _isIEedge === false
      ) {
        // is Google Chrome
        is_chrome = true;
      } else {
        //safari usa a google
        if (navigator.userAgent.toLowerCase().indexOf('safari/') > -1 && this.platform.is('ios')) {
          is_chrome = true;
        } else {
          // not Google Chrome 
          is_chrome = false;
        }
      }

    }


    return is_chrome;
  }

}
