import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { LoadService } from 'src/app/services/load.service';
import { CommunicationService, CommunicationInterface } from 'src/app/services/communication.service';
import { filter } from 'rxjs/operators';

// import { FcmService } from 'src/app/services/fcm.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { TextService } from 'src/app/services/text.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: [
    './loading.scss',
    './main-layout.component.scss'
  ],
})
export class MainLayoutComponent implements OnInit {

  is_load: boolean = false;

  message: string = null;

  theme: string = null;

  is_showing: boolean = false;

  is_show_menu: boolean = false;

  is_toast: boolean = false;
  is_pop: boolean = false;
  is_pop_alert: boolean = false;

  pop_icon: string = "fas fa-exclamation";

  text: any;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private loadService: LoadService,
    private communicationService: CommunicationService,
    // private fcmService: FcmService,
    private analyticsService: AnalyticsService,
    private textService: TextService,
  ) { }

  ngOnInit() {


    this.text = this.textService.get();
    this.load();
    this.listen();

  }

  async load() {

    await this.authService.loginToApi();
    //validar q no haya session

    if (this.userService.getSession() == null) {

      await this.userService.setSession();

      await this.loadService.init();

    }

    // this.fcmService.loadNotification();

    this.is_load = true;

    const user = this.userService.getSession();

    this.analyticsService.gaConfig(user.id);

  }

  onCloseMenu() {
    this.is_show_menu = false;
  }

  dialog_time_out: any;

  listen() {

    this.communicationService.getDataObservable.pipe(
      filter((communicationData: CommunicationInterface) => {
        return communicationData.page === 'main';
      })
    ).subscribe((communicationData: CommunicationInterface) => {

      if (communicationData.action == 'menu') {

        if (communicationData.data.menu != null) {

          this.is_show_menu = communicationData.data.menu

        } else {
          this.is_show_menu = !this.is_show_menu;
        }


      }


    });

    this.communicationService.getDataObservable.pipe(
      filter((communicationData: CommunicationInterface) => {
        return communicationData.page === 'dialog';
      })
    ).subscribe((communicationData: CommunicationInterface) => {

      this.is_toast = true;

      if (this.is_showing) {
        this.message = null;
        this.is_showing = false;
        clearTimeout(this.dialog_time_out);
      }

      if (communicationData.action == 'toast') {

        this.is_showing = true;

        this.message = communicationData.data.message;

        this.theme = 'primary';

        this.dialog_time_out = setTimeout(() => {
          this.message = null;
          this.is_showing = false;
          this.is_toast = false;
        }, 2 * 1000);

      }

      if (communicationData.action == 'alert') {

        this.is_showing = true;

        this.message = communicationData.data.message;

        this.theme = 'warning';

        this.dialog_time_out = setTimeout(() => {
          this.message = null;
          this.is_showing = false;
          this.is_toast = false;
        }, 3 * 1000);

      }

    });

    this.communicationService.getDataObservable.pipe(
      filter((communicationData: CommunicationInterface) => {
        return communicationData.page === 'pop';
      })
    ).subscribe((communicationData: CommunicationInterface) => {


      this.is_pop = true;

      this.message = communicationData.data.message;

      console.log('communicationData.action', communicationData.action);

      if (communicationData.action == 'alert') {

        this.is_pop_alert = true;

      } else {

        this.is_pop_alert = false;

      }

      this.pop_icon = communicationData.data.icon || 'fas fa-exclamation';

    });

  }


  onPopClose() {

    this.is_pop = false;
    this.message = null;
    this.popSend(false);

  }

  onPopOk() {

    this.is_pop = false;
    this.message = null;
    this.popSend(true);

  }

  private popSend(confirm: boolean) {

    this.communicationService.setData({
      page: 'pop-close',
      action: 'confirm',
      data: {
        confirm
      }
    });

  }


}
