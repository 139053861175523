import { Injectable } from '@angular/core';
import * as Parse from "parse";
import { Router } from '@angular/router';
import { ErrorService } from './error.service';
import { RespModel } from '../model/resp.model';
import { Observable, Subject } from 'rxjs';
import { UserModel } from '../model/user.model';
import { environment } from 'src/environments/environment';
import { Platform } from '@ionic/angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { SignInWithApple, AppleSignInResponse, AppleSignInErrorResponse, ASAuthorizationAppleIDRequest } from '@ionic-native/sign-in-with-apple/ngx';
import { ApiService } from './api.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private router: Router,
    private errorService: ErrorService,
    private platform: Platform,
    private http: HttpClient,
    private googlePlus: GooglePlus,
    private signInWithApple: SignInWithApple,
    private readonly apiService: ApiService
  ) { }

  async logout(): Promise<boolean> {
    let is_complete: boolean = false;

    const currentUser = Parse.User.current();
    if (currentUser) {
      await Parse.User.logOut();
      is_complete = true;
    }

    return is_complete;
  }


  async isAuth(): Promise<boolean> {

    let is_auth: boolean = false;

    const currentUser = Parse.User.current();

    if (currentUser) {

      is_auth = true;

    }

    if (!is_auth) {
      this.router.navigate(['/auth']);
    }

    return is_auth;

  }


  /**
   * Envía un correo para recuperar la contraseña
   * @param email 
   */
  async recoveryPassword(email: string): Promise<RespModel> {

    let resp: RespModel = { complete: false };

    try {

      await Parse.User.requestPasswordReset(email);
      resp.complete = true;

    } catch (error) {

      resp.message = this.errorService.convertMessage(error, 'auth');

    }

    return resp;

  }


  /**
   * retorna el id del usuario
   * @param fullname 
   * @param email 
   * @param password 
   */
  async register(fullname: string, email: string, password: string): Promise<RespModel> {

    let resp: RespModel = { complete: false };

    const user = new Parse.User();
    user.set('email', email);
    user.set('password', password);
    user.set('username', email);


    try {

      const r = await user.signUp();
      resp.complete = true;
      resp.data = {
        id: r.id,
        email,
        fullname
      };

    } catch (error) {

      resp.message = this.errorService.convertMessage(error, 'auth');

    }

    return resp;

  }


  /**
   * Inicia la sesión del usuario en el api2 
   */
  async loginToApi(): Promise<boolean> {

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      })
    };

    try {

      const body = {
        token: Parse.User.current().getSessionToken()
      };

      const data: { access_token: string, user: UserModel } = await this.http.post(`${environment.advantask.api2}/auth/loginWithTokenParse`, body, httpOptions).toPromise() as any;
      await this.apiService.setToken(data.access_token);

      return true;

    } catch (error) {

      return false;

    }

  }

  /**
   * Inicia la sesión del usuario
   * @param email 
   * @param password 
   */
  async login(email: string, password: string): Promise<RespModel> {

    let resp: RespModel = { complete: false };

    try {

      const r = await Parse.User.logIn(email, password);
      resp.complete = true;
      resp.data = {
        id: r.get('userConfig').id
      };

    } catch (error) {

      resp.message = this.errorService.convertMessage(error, 'auth');

    }

    return resp;

  }

  loginInGoogle(): Observable<RespModel> {
    let sub = new Subject<RespModel>();
    let resp: RespModel = { complete: false };

    this.googlePlus.login({
      'webClientId': environment.google.client_id,
    }).then(r => {
      let token = r.idToken;
      resp.data = { token };

      resp.complete = true;
    }).catch(r => {
      resp.message = this.errorService.convertMessage(r, 'auth');
    }).finally(() => {
      sub.next(resp);
    });
    return sub;
  }

  async loginApple(): Promise<AppleSignInResponse> {

    const userCurrent = Parse.User.current();

    console.log('userCurrent', userCurrent);

    if (userCurrent) {
      console.log('islogin');
      await Parse.User.logOut();
      console.log('islogout');
    }

    const r = await this.signInWithApple.signin({
      requestedScopes: [
        ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
        ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail
      ]
    });



    return r;

  }

  async registerApple(user_id: string, token: string, email: string) {

    //10Mialiv$

    try {
      const r = await Parse.User.logInWith('apple', {
        authData: {
          id: user_id,
          token
        }
      });

      return {
        ...r.attributes,
        email
      };
    } catch (error) {
      console.log("error register apple", error);

      return;
    }


  }

  async registerAppleUserConfig(user_id: string) {

    const user = Parse.User.current();

    const QueryClass = Parse.Object.extend('UserConfig');
    const query = new Parse.Query(QueryClass);

    console.log('user_id', user_id);
    const userParse = await query.get(user_id);

    console.log("userParse", userParse);

    user.set('userConfig', userParse);

    const r = await user.save();

    console.log("r", r);

  }

  registerWithProvider(provider_name: string, token: any = null): Observable<RespModel> {

    let sub = new Subject<RespModel>();
    let resp: RespModel = { complete: false };

    this.registerProviderGoogle(token).subscribe(r => {
      if (r.complete) {

        Parse.User.become(r.data.session_token).then(r => {
          const data: UserModel = {
            id: r.get("userConfig").id,
            created: r.get('createdAt')
          };
          resp.data = data;
          resp.complete = true;
        }).catch(r => {
          resp.message = this.errorService.convertMessage(r, 'auth');
        }).finally(() => {
          sub.next(resp);
        });

      } else {
        resp.message = r.message;
        sub.next(resp);
      }

    });


    return sub;
  }


  private registerProviderGoogle(token): Observable<RespModel> {
    let sub = new Subject<RespModel>();
    let resp: RespModel = { complete: false };

    const url = environment.advantask.api;

    let source = 'any';

    if (this.platform.is('cordova') && (this.platform.is('iphone') || this.platform.is('ipad'))) {

      source = 'ios';

    }

    this.http.post(`${url}/login/google`, { token, source })
      .subscribe((r: any) => {
        if (r.complete) {
          resp.complete = true;
          resp.data = r.data;
        } else {
          resp.message = r.message;
        }
        sub.next(resp);
      }, (error) => {
        resp.message = error.message;
        sub.next(resp);
      });

    return sub;
  }



}
