import { Injectable } from '@angular/core';

import * as Parse from "parse";

import { GuidePage, UserModel, UserOptions } from '../model/user.model';
import { RespModel } from '../model/resp.model';

import { ErrorService } from './error.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _users: UserModel[] = [];
  private _user: UserModel = null;

  private url = `${environment.advantask.email}`;
  private httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded"
    })
  };

  constructor(
    private errorService: ErrorService,
    private http: HttpClient,
  ) { }

  /**
   * Obtiene todos los usuarios
   * @param user_ids Id de los usuarios a cargar si es null carga todos los usuarios del sistema
   */
  async load(user_ids: string[] = []): Promise<boolean> {

    let is_complete: boolean = false;

    const QueryClass = Parse.Object.extend('UserConfig');
    const query = new Parse.Query(QueryClass);

    query.limit(1000);
    query.containedIn("objectId", user_ids);

    query.ascending('fullname');

    query.select('uid', 'fullname', 'image', 'emails', 'email_send', 'email_login');

    const usersParse = await query.find();

    const users = usersParse.map(u => { return { id: u.id, ...u.attributes } });

    this._users = JSON.parse(JSON.stringify(users));

    this.listen();

    return is_complete;

  }

  /**
   * Escucha los cambios en la base de datos y dispara un observable
   */
  private listen() {

    const userCurrent = Parse.User.current();

    const user_id = userCurrent.get('userConfig').id;

    const QueryClass = Parse.Object.extend('UserConfig');
    const query = new Parse.Query(QueryClass);

    query.equalTo("objectId", user_id);

    query.subscribe().then(s => {

      s.on('update', userParse => {

        const user = { id: userParse.id, ...userParse.attributes };

        console.log('User Update', user);

        this._user = user;

        //observable disparador
        this.getUserListen$.next(JSON.parse(JSON.stringify(this._user)));

      });

    });

  }

  /**
   * Observables
   * reenvia los datos cuando se actualiza el user
   */
  private getUserListen$: Subject<UserModel> = new Subject<UserModel>();
  getUserListen = this.getUserListen$.asObservable();

  /**
   * Obtiene todos los usuarios precargados
   * @param user_ids Lista de los ids de los usuarios, sino se envía devuelve todos
   * @param estricto Si es true y la lista de user_ids es vacía devuelve un array vacio, si es false y la lista de user_ids es vacía obtiene todos los usuarios
   */
  getUsers(user_ids: string[] = [], estricto: boolean = false): UserModel[] {

    let users: UserModel[] = [];

    if (user_ids.length) {

      user_ids.forEach(u_id => {
        const index = this._users.findIndex(u => u.id == u_id);
        if (index != -1) {
          users.push(JSON.parse(JSON.stringify(this._users[index])));
        }
      });

    } else if (estricto == false) {

      users = JSON.parse(JSON.stringify(this._users));

    }

    return users;
  }

  /**
   * Agrega un usuario al listado
   * @param user
   */
  setUsers(user: UserModel) {
    this._users.push(JSON.parse(JSON.stringify(user)));
  }

  /**
   * Obtiene todos los usuarios precargados
   * @param query string para buscar (puede ser parte del nombre o del email)
   */
  getUsersBy(query: string = ''): UserModel[] {

    query = query.toLowerCase();

    let users: UserModel[] = [];

    this.getUsers().forEach(u => {

      let has = false;

      if (u.fullname.toLowerCase().includes(query)) {

        has = true;

      } else {

        u.emails.forEach(e => {
          if (e.toLowerCase().includes(query)) {
            has = true;
          }
        });

      }

      if (has) {
        users.push(u);
      }

    });

    return users;
  }

  /**
   * carga la sesión del usuario
   */
  async setSession(): Promise<boolean> {

    let is_complete = false;

    const userCurrent = Parse.User.current();

    const user_id = userCurrent.get('userConfig').id;

    const QueryClass = Parse.Object.extend('UserConfig');
    const query = new Parse.Query(QueryClass);


    try {

      const userParse = await query.get(user_id);

      this._user = JSON.parse(JSON.stringify(
        {
          id: userParse.id,
          ...userParse.attributes
        }
      ));

      //validar campos

      if (!this._user.contacts) {
        this._user.contacts = [];
      }

      if (!this._user.guides) {
        this._user.guides = [];
      }

      if (!this._user.nps) {
        this._user.nps = [];
      }

      if (!this._user.reminder_days) {
        this._user.reminder_days = [];
      }

      if (!this._user.options) {

        this._user.options = {
          agroup_by: 'person',
          sort_by: 'created',
          show_by: [],
          dashboard_day: 7,
          dashboard_person: 'me'
        };

      } else {

        if (!this._user.options.agroup_by) {
          this._user.options.agroup_by = 'person';
        }

        if (!this._user.options.sort_by) {
          this._user.options.sort_by = 'created';
        }

        if (!this._user.options.show_by) {
          this._user.options.show_by = [];
        }

      }

    } catch (error) {

      // await this.router.navigate(['/auth']);
      const currentUser = Parse.User.current();
      if (currentUser) {
        await Parse.User.logOut();
      }
      window.location.href = '/';

    }

    return is_complete;

  }

  /**
   * Retorna el usuario en sesión
   */
  getSession(): UserModel {

    return JSON.parse(JSON.stringify(this._user));

  }

  /**
   * Obtiene el userConfig mediante el email
   * @param email email del usuario
   */
  async getByEmail(email: string): Promise<UserModel> {

    let user: UserModel = null;

    const QueryClass = Parse.Object.extend('UserConfig');
    const query = new Parse.Query(QueryClass);

    query.containedIn("emails", [email]);

    const userParse = await query.first();

    if (userParse) {

      user = {
        id: userParse.id,
        ...userParse.attributes,
      };

    }

    return user;

  }

  /**
   * Obtiene el userConfig mediante el id localmente
   * @param id id del usuario
   */
  getByIdLocal(id: string): UserModel {

    //buscar localmente
    const index = this._users.findIndex(u => u.id == id);
    if (index != -1) {
      return JSON.parse(JSON.stringify(this._users[index]));
    }

    return null;

  }

  /**
   * Obtiene el userConfig mediante el id
   * @param id id del usuario
   */
  async getById(id: string): Promise<UserModel> {

    //buscar localmente
    const index = this._users.findIndex(u => u.id == id);
    if (index != -1) {
      return JSON.parse(JSON.stringify(this._users[index]));
    }

    //buscar en servidor

    let user: UserModel = null;

    const QueryClass = Parse.Object.extend('UserConfig');
    const query = new Parse.Query(QueryClass);

    query.equalTo("objectId", id);

    const userParse = await query.first();

    if (userParse) {

      user = {
        id: userParse.id,
        ...userParse.attributes,
      };

    }

    return user;

  }

  /**
   * Actualiza datos del userConfig 
   * @param user_id 
   * @param data 
   */
  async updateUserParse(user_id: string, data: UserModel) {

    const query = new Parse.Query('UserConfig');
    const userConfigParse = await query.get(user_id);

    userConfigParse.set('is_ghost', false);
    userConfigParse.set('fullname', data.fullname);
    await userConfigParse.save();

    const userCurrent = Parse.User.current();
    userCurrent.set('userConfig', userConfigParse);

    await userCurrent.save();
  }

  /**
   * Guardamos al usuario en la base de datos
   * @param user es el objeto usuario a crear
   * @returns una promesa de tipo Resp
  */
  async save(user: UserModel, is_ghost: boolean = false): Promise<RespModel> {

    let resp: RespModel = { complete: false };

    //crear la configuracion del usuario
    const ParseClass = Parse.Object.extend('UserConfig');
    const obj = new ParseClass();

    obj.set('is_validated', false);
    obj.set('emails', user.emails);
    obj.set('fullname', user.fullname);
    obj.set('email_login', user.email_login);
    obj.set('email_send', user.email_send);
    obj.set('is_ghost', is_ghost);
    obj.set('guides', []);
    obj.set('contacts', user.contacts || []);

    obj.set('options', {
      agroup_by: 'person',
      sort_by: 'created',
      show_by: [],
      dashboard_day: 7,
      dashboard_person: 'me'
    });


    //default alerts
    // user.alerts = this.alertService.getDefault();
    user.alerts = [
      {
        name: 'group_new',
        state_notification: false,
        state_email: true
      },
      {
        name: 'task_new',
        state_notification: true,
        state_email: true
      },
      {
        name: 'task_due',
        state_notification: false,
        state_email: true
      },
    ];

    try {

      const userParse = await obj.save();

      const user: UserModel = {
        id: userParse.id,
        ...userParse.attributes,
      };

      resp.data = user;

      //si no es ghost asignamos el puntero del usuario al userConfig
      if (!is_ghost) {
        const userCurrent = Parse.User.current();

        userCurrent.set('userConfig', obj);

        userCurrent.save();
      }

      resp.complete = true;

    } catch (error) {

      resp.message = this.errorService.convertMessage(error, 'user');

    }

    return resp;

  }


  /**
   * ACtualiza los datos del usuario en sesión
   * @param user es el objeto a actualizar
   */
  async update(user: UserModel): Promise<RespModel> {

    let resp: RespModel = { complete: false };

    const userCurrent = Parse.User.current();

    const ParseClass = Parse.Object.extend('UserConfig');

    const query = new Parse.Query(ParseClass);

    query.equalTo('objectId', userCurrent.get('userConfig').id)

    const obj = await query.first();

    try {

      if (user.id) {
        delete user.id;
      }

      const r = await obj.save(user)

      resp.complete = true;

      const userUpdate: UserModel = {
        id: r.id,
        ...r.attributes,
      };

      resp.data = JSON.parse(JSON.stringify(userUpdate));

      this._user = JSON.parse(JSON.stringify(userUpdate));

    } catch (error) {

      resp.message = this.errorService.convertMessage(error, 'user');

    }

    return resp;
  }


  /**
  * Enviamos el link para validar el nuevo email
  * @param fullname contacto
  * @param email contacto
  */
  async sendEmail(type: string = 'confirmation', user_data: any = null): Promise<RespModel> {

    let resp: RespModel = {
      complete: false
    };

    let user_send: any;

    if (user_data) {

      user_send = { ...user_data };

      if (user_send.image) {
        user_send.image = btoa(user_send.image.toString())
      }

    } else {

      const user = this.getSession();

      user_send = {
        id: user.id,
        fullname: user.fullname,
        email_send: user.email_send
      }

      if (user.image) {
        user_send.image = btoa(user.image.toString())
      }

    }

    try {

      const r = await this.http.post<RespModel>(`${this.url}/user`, `type=${type}&user=${JSON.stringify(user_send)}&production=${environment.production}`, this.httpOptions).toPromise();

      if (r.complete) {
        resp.complete = true;
        resp.message = this.errorService.convertMessage({ complete: true, code: 'mail_invitation_complete' });
      } else {
        resp.message = this.errorService.convertMessage(r);
        this.errorService.sendMailToAdmin(r);
      }

    } catch (error) {
      resp.message = this.errorService.convertMessage(error);
    }


    return resp;
  }


  /**
   * Retorna si muestra la guía
   * @param page la página de guía
   */
  showGuide(page: GuidePage): boolean {
    let is_show: boolean = false;

    const user = this.getSession();

    if (!user.guides.includes(page)) {
      is_show = true;
    }

    if (page == 'group') {
      is_show = false;
    }

    return is_show;

  }


  /**
   * Actualiza la guía
   * @param page la pagina de guía
   */
  async updateGuide(page: GuidePage) {

    const user = this.getSession();

    await this.update({
      guides: [...user.guides, page]
    })

  }

}
